import React, { useState, useEffect } from "react";
import { SunIcon, MoonIcon } from "@heroicons/react/24/outline";

function ThemeToggle() {
  // Initialize theme from localStorage, defaulting to 'light' if not set
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  // Effect to set initial theme on component mount
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    
    // Update class directly instead of toggle
    if (newTheme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    
    // Dispatch custom event when theme changes
    window.dispatchEvent(new CustomEvent('themeChanged'));
  };

  return (
    <button
      type="button"
      onClick={toggleTheme}
      className="flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-primary focus:outline-none"
    >
      <span className="sr-only">Toggle theme</span>
      {theme === 'light' ? (
        <MoonIcon className="h-6 w-6" aria-hidden="true" />
      ) : (
        <SunIcon className="h-6 w-6" aria-hidden="true" />                          
      )}
    </button>
  );
}

export default ThemeToggle;
