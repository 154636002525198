import React, { useState, useEffect } from 'react';
import './styles/App.scss';
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from 'react-ga4';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ErrorBoundary } from 'react-error-boundary';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { useCampaignContext } from "./contexts/CampaignContext";
import { StripeProvider } from './contexts/StripeContext';
import { HelmetProvider } from 'react-helmet-async';
import ThemeSelector from './containers/ThemeSelector';
import LightTheme from "./themes/LightTheme";
import DarkTheme from "./themes/DarkTheme";
import "./fonts/NotoSans-Regular.ttf";


import CampaignList from "./routes/CampaignList";
import Campaign from "./routes/Campaign";
import Session from "./routes/Session";
import Landing from './routes/Landing';
import Join from './routes/Join';
import Oops from './routes/Oops';
import CampaignForm from './routes/CampaignForm';
import Account from './routes/Account';
import Feedback from './routes/Feedback';
import Discord from './routes/Discord';
import Pricing from './routes/Pricing';
import Checkout from './routes/Checkout';
import Return from './routes/Return';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsConditions from './routes/TermsConditions';
import Support from './routes/Support';

import TopMenu from './components/Infrastructure/TopMenu';
import Loading from './components/Atoms/Loading';
import { PERMISSIONS } from './utilities/Constants';

ReactGA.initialize('G-M91PZV81D0');

const stripePromise = loadStripe('pk_live_51PKTWn2NRmM6W80weQNCI3YLOqKw1smfiNQ458e2312IcV0hhsa36otDL0QVgIHJLH8bPsTC4m4XRIYKap6zg5Xu00Cs6vjmzO');

export default function App(props)
{
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const campaignContext = useCampaignContext();
  const { currentTheme } = campaignContext;  

  useEffect(() =>
  {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))
    {
      document.documentElement.classList.add('dark')
    } else
    {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  function ErrorFallback({ error })
  {
    return <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  }

  if (isLoading)
  {
    return <Loading />;
  }

  return (
    <Elements stripe={stripePromise}>
      <LightTheme />
      <DarkTheme />
      <StripeProvider customerId={user?.sub}>
        <ThemeSelector currentTheme={currentTheme} >
          <HelmetProvider>
          <div id="AppContainer">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <TopMenu />
              <div id="AppBody" className='flex flex-col flex-1 bg-background text-textprimary overflow-hidden'>
                <Routes>
                  <Route path="/" element={<Landing />} />                  
                  <Route path="/discord" element={<Discord />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-and-conditions" element={<TermsConditions />} />
                  <Route path="/checkout" element={<ProtectedRoute component={Checkout} />} />
                  <Route path="/return" element={<Return />} />
                  <Route path="campaigns">
                  <Route index element={<ProtectedRoute component={CampaignList} />} />
                    <Route path=":campaignId/edit" element={<ProtectedRoute component={CampaignForm} PermissionsRequired={PERMISSIONS.Editor} />} />
                    <Route path=":campaignId/session/:sessionId" element={<ProtectedRoute component={Session} />} />
                    <Route path=":campaignId/session/:sessionId/:noteId" element={<ProtectedRoute component={Session} />} />
                    <Route path="/campaigns/:campaignId" element={<ProtectedRoute component={Campaign} />} />
                    <Route path="/campaigns/:campaignId/join/:code/:permission" element={<ProtectedRoute component={Join} />} />
                    <Route path="/campaigns/create" element={<ProtectedRoute component={CampaignForm} />} />
                  </Route>
                  <Route path="/settings/account" element={<ProtectedRoute component={Account} />} />                  
                  <Route path="/support" element={<Support />} />
                  <Route path="*" element={<Oops />} />
                </Routes>
              </div>
            </ErrorBoundary>
          </div>
          </HelmetProvider>
        </ThemeSelector>
      </StripeProvider>
    </Elements>
  );
}